
// DO NOT CHANGE THE VALUE OF THIS VARIABLE
// Value will be replaced by startup script during nginx deployment and will contain 'dev', 'qa', or 'prod'
export const ENVIRONMENT = '';

export const devSignicatDomain = "signicat.dev";
export const qaSignicatDomain = "qa.signicat.dev";
export const prodSignicatDomain = "signicat.com";
export const apiSubdomain = "api";
export const dashboardSubdomain = "dashboard";
const sessionStoragePrefix = "signicatDashboard";
export const isSignicatTenantKey = `${sessionStoragePrefix}IsSignicatTenant`;
export const apiUrlKey = `${sessionStoragePrefix}ApiUrl`;
export const environmentKey = `${sessionStoragePrefix}Environment`;
